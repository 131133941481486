import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestUnfinishedList, requestUnfinishedService, updateServiceReview,
    reduceDateSelected, reduceStatusSelected
} from "../../../../actions/unfinished.js"
import { format, addDays} from "date-fns"

export default function UnfinishedFilters() {
    const date = useSelector((state) => state.unfinished_date)
    const status = useSelector((state) => state.unfinished_status)
    const dispatch = useDispatch() 

    useEffect(() => {
        if (date != null && status != null) {
            dispatch(requestUnfinishedList( String(  format( addDays( new Date(date), 1), "yyyy-MM-dd") ) , status))
        } else if (date != null) {
            dispatch(requestUnfinishedList( String ( format(  addDays( new Date(date), 1 ), "yyyy-MM-dd") ), status))
        } else {
            dispatch(requestUnfinishedList(date,  status) ) 
        }
    }, [date, status] )


    return(
        <Container>
            <Row>
                <Col>
                    <FloatingLabel label= "Date">
                        <Form.Control
                            type="date"
                            value={ date} 
                            onChange={
                                (event) => {
                                    console.log("Cleared value: ", event.target.value)
                                    if (event.target.value == '') {
                                        dispatch(reduceDateSelected(null))
                                    } else {
                                        dispatch(reduceDateSelected(event.target.value))
                                    }
                                   
                                }}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label="Services Status">
                        <Form.Select value={status} onChange={(event) => { dispatch(reduceStatusSelected(event.target.value)  ) }}>
                            <option value='null'></option>
                             <option value = "Scheduled"> Scheduled </option>
                             <option value = "NOT_TESTED"> Not tested </option>
                         </Form.Select>
                    </FloatingLabel>
                </Col>        
            </Row>
        </Container>
    )
}