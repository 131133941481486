import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import UnfinishedRow from "./UnfinishedRow"
import UnfinishedFilters from "./filters"
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestUnfinishedList
} from "../../../../actions/unfinished.js"

export default function UnfinishedServices(){
    const list = useSelector((state) => state.unfinished_list)
    const dispatch = useDispatch()
    const date = useSelector((state) => state.unfinished_date)
    const status = useSelector((state) => state.unfinished_status)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        dispatch(requestUnfinishedList(date, status)).then((data, err) => {
            setLoading(false)
        })
    }, [])

    const renderRows = list.map( (service) => <UnfinishedRow service = {service} />)

    return(
        <Container>
            <Row>
                <Col>
                    <h2> Incomplete Services</h2>
                    <p>  This is a list of all services that were scheduled
                    but not completed on the date scheduled.<br/> Clicking on REVIEWED will take that service out of this list. <br/>
                    Double clicking on the service row will open the customer view </p>
                </Col>
             
            </Row> 
            <Row>
                <Col>
                    <UnfinishedFilters / >
                </Col>
            </Row>
               <hr/>
            <Row className="scrollable-table">
                <Col  >
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th> Loc. Name - Street </th>
                                 <th> SN </th>
                                <th> Date </th>
                                <th> Status </th>
                                <th> Tester Comment </th>
                                <th> Reviewed </th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                loading? 
                                    <Spinner />
                                : 
                                    <> 
                                        {renderRows}
                                    </>

                            }
                        </tbody>
                    </Table>
                </Col>
            </Row> 
                <hr/>
             <Row>
                <Col>
                    <p>
                        <strong>Scheduled</strong> - Stop was dispatch but test report was not submitted <br />
                        <strong>NOT_TESTED</strong> - Tester marked this service as not completed
                    </p>
                </Col>
            </Row> 
       </Container>
    )
}