export const updateServiceReview = (serviceID) => (
	fetch("backflow/unfinished/reviewed", {
		method: "PUT",
		body: JSON.stringify({ serviceID: serviceID }),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestUnfinishedList = (date, status) => (
	fetch("backflow/unfinished?" + new URLSearchParams({
		date: date, 
		status: status
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestUnfinishedService = (serviceID) => (
	fetch("backflow/unfinished/service?" + new URLSearchParams({
		serviceID: serviceID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)






