import {
    SET_SERVICES_LIST, CLEAR_SERVICES_LIST
} from "../../actions/unfinished";

const _null = [] 


export default (state = _null, { type, list }) => {
    Object.freeze(state);
    switch (type) {
        case SET_SERVICES_LIST:
            return list;
        case CLEAR_SERVICES_LIST:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};