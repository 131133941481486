import * as server from "../util/unfinishedServer";

export const SET_SERVICES_LIST = "SET_SERVICES_LIST"
export const CLEAR_SERVICES_LIST = "CLEAR_SERVICES_LIST"
export const SET_DATE_SELECTED = "SET_DATE_SELECTED"
export const CLEAR_DATE_SELECTED = "CLEAR_DATE_SELECTED"
export const SET_STATUS_SELECTED = "SET_STATUS_SELECTED"
export const CLEAR_STATUS_SELECTED = "CLEAR_STATUS_SELECTED"


const setServicesList = (list) => ({
    type: SET_SERVICES_LIST, 
    list
})
const setDateSelected = (date) => ({
    type: SET_DATE_SELECTED,
    date
})

const setStatusSelected = (status) => ({
    type: SET_STATUS_SELECTED, 
    status
})

const clearServicesList = () => ({
    type: CLEAR_SERVICES_LIST
})
const clearDateSelected = () => ({
    type: CLEAR_DATE_SELECTED
})

const clearStatusSelected = () => ({
    type: CLEAR_STATUS_SELECTED
})

export const clearAll = () => dispatch => {
    return new Promise(async (resolve, reject) => {
        let promises = [];
        promises.push(dispatch(clearDateSelected()))
        promises.push(dispatch(clearStatusSelected()))
        promises.push(dispatch(clearServicesList()))
        Promise.all(promises).then((data, err) => {
               resolve()
        })
    })
}


export const reduceList = (list) => dispatch => {
    return new Promise(async (resolve, reject) => {
        await dispatch( setServicesList(list) )
        resolve() 
    })
}

export const reduceDateSelected = (date) => dispatch => {
    return new Promise(async (resolve, reject) => {
        await dispatch(setDateSelected(date))
        resolve()
    })
}

export const reduceStatusSelected = (status) => dispatch => {
    return new Promise(async (resolve, reject) => {
        await dispatch(setStatusSelected(status))
        resolve()
    })
}


export const requestUnfinishedList = (date, status) => dispatch => {
    return new Promise(async (resolve, reject) => {
        const response = await server.requestUnfinishedList(date, status)
        const data = await response.json()
        if (response.ok) {
            dispatch(setServicesList (JSON.parse(data).list) )
            resolve()
        }
    })
}

export const requestUnfinishedService = (serviceID) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.requestUnfinishedService(serviceID)
        const data = await response.json()
        if (response.ok) {
            resolve(JSON.parse(data))
        }
    })
}

export const updateServiceReview = (serviceID) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateServiceReview(serviceID)
        const data = await response.json()
        if (response.ok) {
            resolve()
        }
    })
}  